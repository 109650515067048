// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as constants from '../constants';
import SERVER_INFO from './ServerInfo.graphql';
import UPDATES from './Updates.graphql';
import BACKUP from './Backup.graphql';
import OVERUSED_SUBSCRIPTIONS from './OverusedSubscriptions.graphql';
import MY_SUBSCRIPTIONS_QUERY from './MySubscriptions.graphql';
import IP_BAN_QUERY from './IpBan.graphql';

export default {
    [constants.SERVER_INFO]: SERVER_INFO,
    [constants.UPDATES]: UPDATES,
    [constants.BACKUP]: BACKUP,
    [constants.OVERUSED_SUBSCRIPTIONS]: OVERUSED_SUBSCRIPTIONS,
    [constants.MY_SUBSCRIPTIONS]: MY_SUBSCRIPTIONS_QUERY,
    [constants.IP_BAN]: IP_BAN_QUERY,
};
