// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Outlet, useLoaderData, useMatches, useParams, useLocation } from 'react-router-dom';
import PageLayout from './PageLayout';

const CpPagesLayout = () => {
    const { showFrames, pageHeader, pageSidebar } = useLoaderData();
    const matches = useMatches().filter(({ handle }) => !!handle);
    const params = useParams();
    const location = useLocation();

    const matchesContext = {};
    const breadcrumbs = matches.reduce((crumbs, match) => {
        if (!match.handle.crumb) {
            return crumbs;
        }

        const matchCrumb = match.handle.crumb({
            data: match.data,
            matchesContext,
            params,
            location,
        });

        if (!matchCrumb) {
            return crumbs;
        }

        if (Array.isArray(matchCrumb)) {
            return [...crumbs, ...matchCrumb];
        }

        return [...crumbs, { href: match.pathname, ...matchCrumb }];
    }, []);

    const reversedMatches = matches.slice().reverse();
    const returnUrl = reversedMatches.find(({ handle }) => handle.returnUrl)?.handle.returnUrl({ params });
    const helpContext = reversedMatches.find(({ handle }) => handle.helpContext)?.handle.helpContext;
    const secondary = reversedMatches.find(({ handle }) => handle.secondary)?.handle.secondary({ location, params });


    const lastBreadcrumbTitle = breadcrumbs[breadcrumbs.length - 1]?.title;
    // Check only matches that affects breadcrumbs or page title and ignore other e.g. Drawer or Dialog page
    const layoutMatches = matches.filter(({ handle }) => handle.crumb || typeof handle.pageTitle === 'function');
    const lastLayoutMatch = layoutMatches.at(-1);
    const pageTitle = lastLayoutMatch?.handle.pageTitle
        ? lastLayoutMatch.handle.pageTitle({
            data: lastLayoutMatch.data,
            matchesContext,
            params,
            location,
        }) || lastBreadcrumbTitle
        : lastBreadcrumbTitle;

    const lastMatch = matches.at(-1);
    const key = typeof lastMatch?.handle?.key === 'function' ? lastMatch.handle.key({ params }) : undefined;

    const data = {
        showFrames,
        pageHeader: {
            ...pageHeader,
            returnUrl: pageHeader.returnUrl || returnUrl,
            helpMenu: {
                ...pageHeader.helpMenu,
                helpContext,
            },
        },
        pageSidebar,
        pageContentHeader: {
            pageTitle,
            breadcrumbs,
        },
        secondary,
    };

    return (
        <PageLayout {...data}>
            <Outlet key={key} />
        </PageLayout>
    );
};

export default CpPagesLayout;
