// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { odbcAddRoute } from 'routes/odbcAddRoute';
import { odbcEditRoute } from 'routes/odbcEditRoute';
import apolloClient from '../apolloClient';

import ODBC_SERVER_LIST from 'queries/OdbcServerList.graphql';

export const odbcServerRoute = {
    path: 'odbc',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/OdbcPage/OdbcServerPage" */
        'components/pages/OdbcPage/OdbcServerPage'
    ),
    loader: async () => {
        const input = readGraphQlListInputFromStorage({ key: 'OdbcServerPage' });
        await apolloClient.query({
            query: ODBC_SERVER_LIST,
            variables: { input },
        });
    },
    handle: {
        crumb: () => ({
            title: Locale.getSection('components.pages.Odbc').lmsg('serverTitle'),
        }),
        helpContext: 'odbc_connection_config',
    },
    children: [
        odbcAddRoute,
        odbcEditRoute,
    ],
};
