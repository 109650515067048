// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ApolloClient, ApolloLink, InMemoryCache, defaultDataIdFromObject, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { SentryLink } from 'apollo-link-sentry';
import { extractFiles } from 'extract-files';

const sentryLink = new SentryLink({
    setTransaction: false,
});

const errorLink = onError(error => {
    if (error.networkError?.statusCode === 400) {
        window.location.reload();
    }
});

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
};

const tokenEl = document.getElementById('forgery_protection_token');
if (tokenEl) {
    headers['X-Forgery-Protection-Token'] = tokenEl.content;
}

const fetchParams = {
    uri: '/cp/graphql',
    headers,
};
const terminatingLink = ApolloLink.split(
    operation => operation.getContext().noBatch || extractFiles(operation).files.size > 0,
    createUploadLink(fetchParams),
    new BatchHttpLink({
        ...fetchParams,
        batchMax: 10,
        batchInterval: 10,
    }),
);

const apolloClient = new ApolloClient({
    link: from([errorLink, sentryLink, terminatingLink]),
    cache: new InMemoryCache({
        typePolicies: {
            AdditionalKey: {
                keyFields: ['keyNumber'],
            },
            Domain: {
                fields: {
                    hosting: {
                        merge: true,
                    },
                    webScriptingSettings: {
                        merge: true,
                    },
                },
            },
            ExtensionTab: {
                keyFields: false,
            },
            SiteButton: {
                keyFields: false,
            },
            Site: {
                keyFields: ['id'],
            },
            ConfigSectionGdprCookie: {
                keyFields: ['name'],
            },
            User: {
                keyFields: ['login'],
            },
            UserLogo: {
                merge: true,
            },
            UserPermissions: {
                merge: true,
            },
            Mode: {
                merge: true,
            },
            Notification: {
                keyFields: [],
            },
            PrimaryKey: {
                keyFields: ['pleskKeyId'],
            },
            WebsitesDiagnostic: {
                keyFields: [],
                fields: {
                    settings: {
                        merge: true,
                    },
                },
            },
            ServerMail: {
                keyFields: [],
                fields: {
                    spamAssassin: {
                        merge: true,
                    },
                },
            },
            Capability: {
                keyFields: [],
                fields: {
                    mailServer: {
                        merge: true,
                    },
                    packages: {
                        merge: true,
                    },
                },
            },
            Dns: {
                keyFields: [],
            },
            Query: {
                fields: {
                    additionalKeys: {
                        merge: false,
                    },
                    recentSearch: {
                        merge: false,
                    },
                    node: {
                        read(_, { args: { id }, toReference }) {
                            const __typename = atob(id).split(':')[0];
                            return toReference({ id, __typename });
                        },
                    },
                },
            },
            Server: {
                keyFields: [],
                fields: {
                    firewall: {
                        merge: true,
                    },
                    components: {
                        merge: false,
                    },
                },
            },
            DnsTemplate: {
                keyFields: [],
            },
            DnsSoaRecordTemplate: {
                keyFields: [],
            },
            TaskList: {
                keyFields: [],
            },
            IpAddress: {
                fields: {
                    clients: {
                        merge: false,
                    },
                },
            },
            IpAddressList: {
                keyFields: [],
                fields: {
                    list: {
                        merge: false,
                    },
                },
            },
            VirtualDirectory: {
                keyFields: ['key'],
            },
        },
        dataIdFromObject: object => {
            switch (true) {
                case object.__typename?.startsWith('Config'):
                    return object.__typename;
                default:
                    return defaultDataIdFromObject(object);
            }
        },
    }),
});

export default apolloClient;
