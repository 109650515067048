// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import apolloClient from '../apolloClient';
import { gql } from '@apollo/client';
import homeQueries from 'components/pages/HomePage/queries';

export const homeRoute = {
    path: 'home',
    componentLoader: () => import(/* webpackChunkName: "components/pages/HomePage" */'components/pages/HomePage'),
    loader: async () => {
        const { data: { viewer: { type } } } = await apolloClient.query({
            query: gql`
                                                query {
                                                    viewer {
                                                        login
                                                        type
                                                    }
                                                }
                                            `,
        });

        if (type !== 'ADMIN') {
            throw new Response('Not Found', { status: 404 });
        }

        const response = await Promise.allSettled(
            Object.values(homeQueries).map(query => apolloClient.query({
                query,
                fetchPolicy: 'network-only',
            })),
        );

        const data = Object.keys(homeQueries).reduce((acc, block, i) => ({
            ...acc,
            [block]: { data: response[i].value?.data, error: response[i].reason?.message },
        }), {});

        return { data };
    },
    handle: {
        helpContext: 'home.admin',
    },
};
